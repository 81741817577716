.App {
  text-align: center;
}
* {
  font-family: "Poppins", sans-serif !important;
}
.heading--large {
  font-family: "Poppins", sans-serif;
  font-size: 45px;
  font-weight: 700;
  line-height: 54.5px;
  margin: 0;
}
.faded__text {
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: lighter;
  margin: 0;
}
.text--description {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 200;
  margin: 0;
}
.btn--primary {
  background-color: #269e40;
  color: white;
  border: none;
  border-radius: 3px;
  padding: 12px;
  font-size: 18px;
  transition: 0.2s ease-in;
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
}
.btn--primary > span {
  width: 18px !important;
  height: 18px !important;
}
.btn--secondary {
  color: #a3a3a3;
  border: 1px solid #a3a3a3;
  background-color: white;
  border-radius: 3px;
  padding: 12px;
  font-size: 18px;
  transition: 0.2s ease-in;
}
.btn--small {
  background-color: #269e40;
  color: white;
  border: none;
  border-radius: 3px;
  padding: 12px;
  font-size: 14px;
  transition: 0.2s ease-in;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.btn--primary:hover {
  background-color: #54af69;
  cursor: pointer;
}
.btn--small:hover {
  background-color: #54af69;
  cursor: pointer;
}
.btn--outlined {
  background-color: white;
  color: #269e40;
  border: 1px solid #269e40;
  border-radius: 3px;
  padding: 10px;
  font-size: 14px;
  transition: 0.2s ease-in;
  font-weight: 500;
}
.btn--outlined:hover {
  background-color: rgb(243, 243, 243);
  cursor: pointer;
  color: #4d9f5f;
  border: 1px solid #4d9f5f;
  border-radius: 3px;
  padding: 10px;
  font-size: 14px;
  transition: 0.2s ease-in;
  font-weight: 500;
}

@media only screen and (max-width: 576px) {
  .heading--large {
    font-family: "Poppins", sans-serif;
    font-size: 28px;
    font-weight: 700;
    line-height: 33.83px;
    margin-top: 10px;
  }
}
